import { makeStyles } from '@material-ui/core';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import colors from '../../../assets/colors';
import botella from '../../../assets/images/botlla1.png'


const theme = createTheme(); // Puedes personalizar tu tema aquí

export const useStyles = makeStyles(() => ({
  btnScan: {
    margin: '20%',
    padding: '20% 10%',
    backgroundColor: 'black',
    borderRadius: 10,
    color: 'white',
    fontSize: 'x-large'
  },
  containerMain: {
    flex: 1,
    width: '100%',
    display: 'flex',
    height: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  camera: {
    width: '100%',
    height: '6vh',
    display: 'flex',
    marginTop: '5%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textCamera: {
    padding: '10px',
    fontWeight: 'bold',
    fontSize: '4vw',
    margin: '5% 1%',
  },
  divFather: {
    height: '75vh',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '90%',
    maxWidth: 600
  },
  inputQR: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 25px',
  },
  containerBackground: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  qr: {
    width: '100%',
    maxWidth: 450,
    alignSelf: 'center',
    padding: 25,
    zIndex: 999
  },
  formContainer: {
    flex: 1,
    display: 'flex',
    width: '100%',
    maxWidth: 450,
    marginTop: 20
  },
  title: {
    width: '90%',
    alignSelf: 'center',
  },
  input: {
    width: '100%',
    marginRight: '1%',
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',
    textAlign: 'center',
    padding: '5%',
    outline: 'none',
    fontWeight: 'bold',
    // textTransform: 'lowercase',
    border: `2px solid ${colors.palette.primary}`,
    '&::placeholder': {
      color: colors.palette.secondary,
      textAlign: 'center'
    }
  },
  footer: {
    textAlign: 'center'
  },
  textFooter: {
    margin: '5% 1%',
    fontSize: '4.5vw',
    backgroundColor: 'lightgray',
    fontStyle: 'italic',
    padding: '5px',
  },
  btnOK: {
    backgroundColor: '#000000d4',
    color: 'white',
    borderRadius: '0 5px 5px 0',
    borderStyle: 'none',
    padding: '10px',
  },
  okScreen: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '75vh',
    width: '80%',
    alignSelf: 'center',
  },
  button: {
    WebkitBorderRadius: '5px',
    borderRadius: '5px',
    backgroundColor: '#da1325',
    color: '#ffffffff',
    border: 'none',
    alignSelf: 'center',
    padding: '10px 5px',
    width: '100%',
    letterSpacing: 3
  },
  containerLoading: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  containerWallet: {
    position: "relative",
    height: "50vh",
    width: "80vw",
    margin: "15px",
    backgroundColor: "white",
    borderTop: " 1px solid lightcoral",
    borderBottom: " 1px solid lightcoral",
    borderRight: " 1px solid lightcoral",
    borderRadius: "0 10px 10px 0",
    overflow: "hidden",
    boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.75)",
  },
  beforecontainerWallet: {
    content: "",
    position: "absolute",
    top: "0",
    left: "-20px",
    height: "100%",
    width: "20px",
    background: "url(${botella}) no-repeat",
    backgroundSize: "cover",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
  },
  contentWallet: {
    flex: 1,
    padding: theme.spacing(2),
  },

  containerNumberWallet: {
    display: 'flex',
    alignItems: 'center',
    color: 'red',
    fontSize: '1.5rem',
  },
  numberWallet: {
    fontSize: '4.5rem',
    fontWeight: 'bold',
    color: 'red',
    lineHeight: '1.5', // Ajusta la altura de línea para ocupar 2 líneas de alto
    marginRight: theme.spacing(1),
  },
  textWalletContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  textWallet: {
    fontWeight: 'bold',
    fontSize: '0.9rem !IMPORTANT', // Ajusta el tamaño del texto según tus necesidades
  },
  buttonWallet: {
    //marginRight: '2px',
    padding: '15px',
    width: '100%',
    fontWeight: 'bold',
    fontSize: '0.8rem',
    //WebkitBorderRadius: '5px',
    borderRadius: '0 0 0 5px',
    backgroundColor: 'rgba(255, 0, 0, 0.87)',
    color: 'rgb(245, 245, 245)',
    border: 'none',
    alignSelf: 'center',
    border: '1px solid rgba(255, 0, 0, 0.87)',
    '&:hover': {
      backgroundColor: 'rgba(255, 0, 0, 0.64)',
    },
  },

  buttonWallet2: {
    //marginLeft: '2px',
    padding: '15px',
    width: '100%',
    fontWeight: 'bold',
    fontSize: '0.8rem',
    //WebkitBorderRadius: '5px',
    borderRadius: '0 0 5px 0',
    backgroundColor: 'white',
    color: 'rgba(255, 0, 0, 0.87)',
    border: 'none',
    alignSelf: 'center',
    border: '1px solid rgba(255, 0, 0, 0.87)',
    '&:hover': {
      color: 'white',
      backgroundColor: 'rgba(249, 141, 141, 0.64)',
    },
  },
  card: {
    backgroundColor: "#da1325",
    margin: "1rem",


  },
  cardContent: {
    margin: '0px !important',
    padding: '0px !important',

  },
  floatingRedButton: {
    position: 'fixed',
    bottom: '13%',
    width: '8rem',
    height: '3rem',
    left: '50%', // Centrar horizontalmente en relación con el contenedor padre
    transform: 'translateX(-50%)',
    backgroundColor: '#da1325',
    color: '#ffffff',
    // borderRadius: '20%',
    cursor: 'pointer',
    boxShadow: '3px 5px 8px rgba(0, 0, 0, 0.4)', // Añade una sombra
    '&:hover': {
      border: '3px solid white',
      backgroundColor: '#da1325'
    },
  },
  iconQr: {
    width: '30%',
    padding: '2px',
  },

  button2: {
    WebkitBorderRadius: '5px',
    borderRadius: '5px',
    backgroundColor: '#da1325',
    color: '#ffffffff',
    border: 'none',
    alignSelf: 'center',
    padding: '10px 5px',
    width: '100%',
    letterSpacing: 3
  },


}));