import { makeStyles } from "@material-ui/core";
import colors, { paleta } from "../assets/colors";

export const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  bannerTitle: {
    backgroundColor: "#da1325",
    height: "6vh",
    display: "flex",
    borderRadius: "0 0 10px 10px",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    top: "-2px",
    zIndex: 9,
  },
  divFather: {
    flex: 1,
    //maxWidth: 600,
    width: "100%",
    height: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    overflow: "auto",
    //marginBottom: '75px',
  },
  containerBackground: {
    flex: 1,
    height: "100%",
    display: "flex",
    padding: "16px 0",
    flexDirection: "column",
    borderTopLeftRadius: "58px",
    borderTopRightRadius: "58px",
    //  backgroundColor: colors.palette.white,
    backgroundColor: "red",
  },
  containerLoading: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },

  container: {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
  },
  content: {
    display: "contents",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },

  containerLoading: {
    display: "flex",
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  containerBackgroundEmpty: {
    flex: 1,
    padding: 30,
    backgroundColor: colors.palette.white,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    borderTopRightRadius: "58px",
    borderTopLeftRadius: "58px",
    paddingRight: 16,
    paddingLeft: 16,
    paddingBottom: 16,
  },
  button: {
    cursor: "pointer",
    WebkitBorderRadius: "50px",
    borderRadius: "50px",
    backgroundColor: "#231f20ff",
    color: "#ffffffff",
    border: "none",
    alignSelf: "center",
    flex: 1,
    padding: 8,
    paddingRight: 16,
    paddingLeft: 16,
    marginTop: 8,
  },
  title: {
    backgroundColor: colors.palette.primary,
    color: colors.palette.white,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    fontSize: 18,
    paddingTop: 18,
    margin: 0,
    paddingBottom: 5,
  },
  buttonContainer: {
    backgroundColor: colors.palette.white,
    justifyContent: "center",
    alignSelf: "center",
  },
  okScreen: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '75vh',
    width: '80%',
    alignSelf: 'center',
  },
  button2: {
    WebkitBorderRadius: '5px',
    borderRadius: '5px',
    backgroundColor: '#da1325',
    color: '#ffffffff',
    border: 'none',
    alignSelf: 'center',
    padding: '10px 5px',
    width: '100%',
    letterSpacing: 3
  },
}));
