import React, { useEffect, useState } from 'react';

import GridCoupons from '../../commons/gridCoupons';
import { getCoupons } from '../../../request';
import Navbar from '../../commons/navBar';
import { useStyles } from '../../stylesCoupons';
import { CircularProgress } from '@material-ui/core';
import iconNav from '../../../assets/icons/_ICONO MIS CUPONES.png';
import { NavBarTitleCustom } from '../../commons/navBar/NavBarTitleCustom';
import { useDispatch } from 'react-redux';
import { setCouponSelected } from '../../../redux/actions/couponsActions';
import NavBarTitle from '../../commons/navBarIcon';
import promocion from '../../../assets/icons/_ICONO CUPONES.png';
import { Button } from "@material-ui/core";

export default function AllCoupons({ history }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [allCoupons, setAllCoupons] = useState([]);

  const [loading, setLoading] = useState(true);
  const Spinner = () => (
    <div className={classes.containerLoading}>
      <CircularProgress disableShrink />
    </div>
  );

  useEffect(() => {
    let exec = true;
    if (exec) {
      getAllCurrentCoupons();
    }
    return () => {
      exec = false;
    };
  }, []);

  useEffect(() => {
    let exec = true;
    if (exec) {
      if (allCoupons?.length === 1) {
        dispatch(setCouponSelected(allCoupons[0]));
        history.push('/consumidores/cupon');
        setLoading(false);
      }

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
    return () => {
      exec = false;
    };
  }, [allCoupons]);

  const getAllCurrentCoupons = async () => {
    await getCoupons().then((res) => setAllCoupons(res));
  };

  const emptyListCoupons = () => (
    <div className={classes.okScreen}>
      <div>
        {/* <ErrorOutlineOutlinedIcon style={{ fontSize: '20vh', color: '#115293' }} /> */}
        {/* <img src={empty} alt="Descripción del GIF" width={"100%"} />*/}
      </div>
      <div
        style={{
          textAlign: "center",
          marginBottom: "10%",
        }}
      >
        <h2>
          No disponible
          <br />

        </h2>
        <p>
          Debe iniciar sesion para poder visualizar sus promociones.
        </p>
      </div>
      <Button
        variant="contained"
        className={classes.button2}
        onClick={() => history.push("/account/login")}
      >
        Iniciar Sesion
      </Button>
    </div>
  );

  return (
    <>
      {loading ? (
        Spinner()
      ) : (
        <>
          <div>
            <Navbar link={'/consumidores/home'} logoRojo={true} colorFondo={'white'} history={history} />

            <div className={classes.bannerTitle}>
              <NavBarTitle img={promocion} title="PROMOCIONES" />
            </div>

            {allCoupons.length ? <NavBarTitleCustom titleCustom={'PROMOCIONES'} iconNav={iconNav} /> : null}
          </div>

          <div className={classes.container}>
            <div className={classes.content}>
              {allCoupons.length ? (
                <GridCoupons titlePrincipal="PROMOCIONES" coupons={allCoupons} history={history} />
              ) :

                emptyListCoupons()

              }
            </div>
          </div>
        </>
      )}
    </>
  );
}
