export default {
  API_URL: 'https://femsa-api.vercel.app/api',
  API_VERSION: '/v3',
  firebaseConfig: {
    apiKey: "AIzaSyD3oQV62NvrgNt7bYi16Qc1YXQSav38BJ4",
    authDomain: "femsa-arg.firebaseapp.com",
    projectId: "femsa-arg",
    storageBucket: "femsa-arg.appspot.com",
    messagingSenderId: "435205195495",
    appId: "1:435205195495:web:96136041785ea785af8442",
    measurementId: "G-87L3H0JMWB",
  },

};


/*firebaseConfig: {
  apiKey: "AIzaSyAmjC9Dsh-J7fzc1yZfDyEZfr5xOHNPZaU",
  authDomain: "femsa-arg-consumidores.firebaseapp.com",
  projectId: "femsa-arg-consumidores",
  storageBucket: "femsa-arg-consumidores.appspot.com",
  messagingSenderId: "919901990172",
  appId: "1:919901990172:web:43e4e89693437f78861ed9",
  measurementId: "G-SQPWP4QESH"
},*/


