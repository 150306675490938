import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    height: 10
  },
  content: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 9
  },
  image: {
    height: "calc(100vw * 30 / 375)"
  },
  text: {
    color: 'white',    
    letterSpacing: 1,
    margin: 0,
    marginLeft: 8,
    marginTop: 3,
    fontSize: 'calc(100vw * 15 / 375)',
  }
}));

const NavbarTitle = ({
  img,
  title
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        {img && (
          <img src={img} alt="icon-cupon" className={classes.image} />
        )}
        <p className={classes.text}>
          {title}
        </p>
      </div>
    </div>
  );
};

export default NavbarTitle;
