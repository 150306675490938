import axios from 'axios';
import moment from 'moment';

const url = 'https://api.femsa.ar'

const TOKEN = 'Bearer UiD '

//const TOKEN = 'Bearer FeMsA@2022'
const today = moment().startOf('day').toISOString();
async function getSliders() {
    try {
        const response = await axios.get(`${url}/api/v3/consumers/sliders`, {
            headers: { Authorization: 'Bearer FeMsA@2024' },
        });
        if (response.status === 200) {
            return response.data;
        } else { console.error(response); }
    } catch (error) {
        return error;
    }
}

async function getShops(lat, lng, uid) {
    console.log("llega lat lng", lat, lng, uid);
    try {
        const response = await axios.get(
            `${url}/api/v3/consumers/shops/by_geo/${lat}/${lng}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': TOKEN + uid,
                },
            });
        if (response.status === 200) {
            console.log(response.data);
            return response.data;
        } else { console.error(response); }
    } catch (error) {
        return error;
    }
}


async function getShopsByZip(code, uid) {
    try {
        const response = await axios.get(
            `${url}/api/v3/consumers/shops/by_zip/${code}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': TOKEN + uid
                },
            });
        if (response.status === 200) {
            return response.data;
        } else { console.error(response); }
    } catch (error) {
        return error;
    }
}

async function getCoupons() {
    try {
        const response = await axios.get(`${url}/generic/v1/coupons`);

        if (response.status === 200) {
            console.log(response.data);
            return response.data;
        } else { console.error(response); }
    } catch (error) {
        return error;
    }
}


async function createTransactionQRdata(qrData, token) {
    ///////
    try {
        console.log('token', token)
        console.log('qrData', qrData)
        const response = await axios.post(`${url}/api/v3/consumers/coupons/burn`,
            qrData,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer UiD ${token}`
                },
            });
        console.log('datarespondese', response.data);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

async function createTransactionWalletQRdata(qrData, token) {
    ///////
    console.log('datatoken', qrData, token)
    try {
        console.log('token', token)
        console.log('qrData', qrData)
        const response = await axios.post(`${url}/api/v3/consumers/billetera_envases/burn_qr`,
            qrData,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer UiD ${token}`
                },
            });
        console.log(response.data);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}


async function getCouponsByUser(token) {
    try {
        //console.log('token', token)
        const response = await axios.get(
            `${url}/api/v1/coupons/by_user`,
            {
                data: {},
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
        if (response.status === 200) {
            return response.data;
        } else { console.error(response); }
    } catch (error) {
        return error;
    }
}


async function getCouponsBurnByUser(token) {
    try {
        console.log('token', token)
        const response = await axios.get(
            `${url}/api/v1/coupons/history/by_user`,
            {
                data: {},
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });

        if (response.status === 200) {
            return response.data;
        } else { console.error(response); }
    } catch (error) {
        return error;
    }
}


async function registerUser(user) {
    try {
        console.log('user', user)
        const response = await axios.post(
            `${url}/api/v3/consumers`,
            user,
            {
                data: {},
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer UiD ${user.uid}`
                },
            });
        console.log('response', response);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}


async function createNewShop(shopObj, token) {
    try {
        const response = await axios.post(
            `${url}/api/v1/shops`,
            shopObj,
            {
                data: {},
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            });
        console.log(response);
        return response.data;
    } catch (error) {
        console.error(error);
    }
}

async function getHistoryCoupons(token) {
    try {
        const response = await axios.get(`${url}/api/v3/consumers/coupons/history/by_user`,
            { headers: { Authorization: `Bearer UiD ${token}` } });
        console.log(response.data, 'getHistoryCoupons');
        const coupons = response.data.filter(coupon => coupon.type === 'normal' && moment(coupon.due_date).isSameOrBefore(moment().add(1, 'month')));
        return coupons;
    } catch (error) {
        console.error(error, "errror");
    }
}

async function getHistoryPromotions(token) {
    try {
        const response = await axios.get(`${url}/api/v3/consumers/coupons/history/by_user`,
            { headers: { Authorization: `Bearer UiD ${token}` } });
        console.log(response.data, 'getHistoryPromotions');
        const promotions = response.data.filter(coupon => coupon.type !== 'normal' && moment(coupon.due_date).isSameOrBefore(moment().add(1, 'month')));
        return promotions;
    } catch (error) {
        console.error(error, "errror");
    }
}

async function getCouponsUser(token) {
    try {
        //console.log('token', token)
        const response = await axios.get(`${url}/api/v3/consumers/coupons/by_user/normal`,
            { headers: { Authorization: `Bearer UiD ${token}` } });
        const dataFiltrada = response.data.filter(item => item.due_date > today);
        return dataFiltrada.reverse();
    } catch (error) {
        console.error(error, "error get cupons by user");
    }
}

async function getPromotionsUser(token) {
    try {
        //console.log('token', token)
        const response = await axios.get(`${url}/api/v3/consumers/coupons/by_user/promotion`,
            { headers: { Authorization: `Bearer UiD ${token}` } });

        const dataFiltrada = response.data.filter(item => item.due_date > today);
        return dataFiltrada.reverse();
        //return response.data;
    } catch (error) {
        console.error(error, "error get cupons by user");
    }
}

async function getWalletBalance(token) {
    try {
        //console.log('token', token)
        const response = await axios.get(`${url}/api/v3/consumers/billetera_envases/balance`,
            { headers: { Authorization: `Bearer UiD ${token}` } });

        return response.data;
    } catch (error) {
        console.error(error, "error get cupons by user");
    }
}

async function getWalletHistory(token) {
    try {
        //console.log('token', token)
        const response = await axios.get(`${url}/api/v3/consumers/billetera_envases/trxs`,
            { headers: { Authorization: `Bearer UiD ${token}` } });

        return response.data;
    } catch (error) {
        console.error(error, "error get cupons by user");
    }
}



export {
    getShopsByZip,
    createNewShop,
    registerUser,
    getCouponsByUser,
    getShops,
    getCouponsBurnByUser,
    createTransactionQRdata,
    getSliders,
    getCoupons,
    getHistoryCoupons,
    getCouponsUser,
    getPromotionsUser,
    getWalletBalance,
    getWalletHistory,
    createTransactionWalletQRdata,
    getHistoryPromotions
}