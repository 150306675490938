import 'react-responsive-carousel/lib/styles/carousel.min.css';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Link } from 'react-router-dom';
import { Button, makeStyles, Grid } from '@material-ui/core';


// importando icons and colors
import Navbar from '../../commons/navBar';

import couponIcon from '../../../assets/icons/_ICONO CUPONES.png';
import CentrosIcon from '../../../assets/icons/_ICONO CENTRO DE CANJE.png';
import favIcon from '../../../assets/icons/_ICONO MIS CUPONES.png';
import billeteraIcon from "../../../assets/icons/_ICONO ENVASES.png";
import bannerCoca from "../../../assets/images/coke223.webp";

import Carousel from '../../commons/carrousel';
import colors, { paleta } from '../../../assets/colors';

const useStyles = makeStyles((theme) => ({
  container: {
    //backgroundColor: '#da1325',
    backgroundColor: 'white',
    height: '100vh',
  },
  content: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },

  buttons: {
    fontSize: '1.7vh',
    fontWeight: 'bold',
    //margin: theme.spacing(0.1),
    height: '20vh',
    color: colors.palette.white,
    backgroundColor: '#da1325',
    justifyContent: 'unset',
    border: '1px',
    borderRadius: '10px',
    textAlign: 'center',
    '&:hover': {
      border: 'unset !important',
      backgroundColor: '#ff3c3c',
    },
    '&:disabled': {
      backgroundColor: 'gray',
    },
  },
  buttons2: {
    fontSize: '1.5vh',
    fontWeight: 'bold',
    //margin: theme.spacing(0.1),
    height: '20vh',
    color: colors.palette.white,
    backgroundColor: 'gray',
    justifyContent: 'unset',
    border: '1px',
    borderRadius: '10px',
    '&:hover': {
      border: 'unset !important',
      backgroundColor: '#ff3c3c',
    },

    textAlign: 'center',

  },
  extendedIcon: {
    //marginRight: theme.spacing(1),
  },
  divContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    // borderTopColor: '#00000099',
    // borderTopWidth: '3px',
    // borderTopStyle: 'solid',
    backgroundColor: colors.palette.primary,
  },

  containerButtons: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    backgroundColor: "white",
    paddingTop: '10px',
    //padding: '2%',
    paddingLeft: '3%',
    paddingRight: '3%',
    width: '100%',
    maxWidth: 450,
    overflow: 'hidden',
    /*borderTopColor: '#9b0303',
    borderTopWidth: '7px',
    borderTopStyle: 'solid',*/
  },
  imgIcons: {

    width: 50,
  },
  imgStyles: {
    height: '100%',
    objectFit: 'cover',
  },
  carousel: {
    display: 'flex',
    backgroundColor: colors.palette.primary,
  },
  lineContainer: {
    height: 1,
    width: '100%',
    backgroundColor: 'white',
  },
  imgBanner: {
    width: '100%',
    padding: '10px',
    border: '2px solid transparent',
    borderRadius: '20px',
  },
}));

export default function ({ history }) {
  const classes = useStyles();

  const { loginReducer } = useSelector((state) => state);
  const { userLoggedIn } = loginReducer;

  const lineBreak = () => <div className={classes.lineContainer}></div>;

  useEffect(() => {
    let excecute = true;
    if (excecute) {
      //console.log(userLoggedIn?.token);
    }
    return () => (excecute = false);
  }, []);


  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <Navbar link="/inicio" logoRojo={false} colorFondo={paleta.rojoCoca} history={history} />

        <Carousel />

        <div className={classes.containerButtons}>
          <Grid container spacing={1}
            padding={1}
            rowSpacing={1}
            textAlign={"center"}
            alignItems={"center"}>
            <Grid item xs={6}>
              <Button
                component={Link}
                to={userLoggedIn?.token ? '/app/mis-cupones-disponibles' : '/consumidores/cupones'}
                variant="outlined"
                size="large"
                color="primary"
                className={classes.buttons}>
                <Grid container spacing={1} textAlign={"center"}
                  alignItems={"center"}
                  justifyContent='center' sx={{ width: '100%', margin: '0px !important', padding: '0px !important' }}>
                  <Grid item xs={12} textAlign={"center"} alignItems={"center"}
                    justifyContent='center'>
                    <img className={classes.imgIcons} src={couponIcon} alt="icon-cupon" />
                  </Grid>
                  <Grid item xs={12} textAlign={"center"} alignItems={"center"}
                    justifyContent='center' sx={{ padding: '0px !important' }}>
                    PROMOCIONES
                  </Grid>
                </Grid>
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                component={Link}
                to={'/consumidores/puntos-venta'}
                variant="outlined"
                size="large"
                color="primary"
                className={classes.buttons}>
                <Grid container spacing={1} textAlign={"center"}
                  alignItems={"center"}
                  justifyContent='center'>
                  <Grid item xs={12} textAlign={"center"} alignItems={"center"}
                    justifyContent='center'>
                    <img className={classes.imgIcons} src={CentrosIcon} alt="icon-centros-canje" />
                  </Grid>
                  <Grid item xs={12} textAlign={"center"} alignItems={"center"}
                    justifyContent='center'>
                    PUNTOS DE VENTA
                  </Grid>
                </Grid>


              </Button>
            </Grid>
            <Grid item xs={6}>

              <Button
                component={Link}
                to={userLoggedIn?.token ? '/app/mis-cupones-canjeados' : '/consumidores/cupones-exclusivos'}
                variant="outlined"
                size="large"
                color="primary"

                className={classes.buttons}
              >
                <Grid container spacing={1} textAlign={"center"}
                  alignItems={"center"}
                  justifyContent='center'>
                  <Grid item xs={12} textAlign={"center"} alignItems={"center"}
                    justifyContent='center'>
                    <img className={classes.imgIcons} src={favIcon} alt="icon-mis-cupones" />
                  </Grid>
                  <Grid item xs={12} textAlign={"center"} alignItems={"center"}
                    justifyContent='center'>
                    Cupones Exclusivos
                  </Grid>
                </Grid>


              </Button>

            </Grid>
            <Grid item xs={6}>

              <Button
                component={Link}
                to={userLoggedIn?.token ? '/app/mi-billetera-envase' : '/consumidores/billetera'}
                variant="outlined"
                size="large"
                color="primary"

                className={classes.buttons}
              >
                <Grid container spacing={1} textAlign={"center"}
                  alignItems={"center"}
                  justifyContent='center'>
                  <Grid item xs={12} textAlign={"center"} alignItems={"center"}
                    justifyContent='center'>
                    <img className={classes.imgIcons} src={billeteraIcon} alt="icon-mis-cupones" />
                  </Grid>
                  <Grid item xs={12} textAlign={"center"} alignItems={"center"}
                    justifyContent='center'>
                    BILLETERA DE ENVASES
                  </Grid>
                </Grid>


              </Button>

            </Grid>
          </Grid>

          {/*<Button
            component={Link}
            to={userLoggedIn?.token ? '/app/mis-cupones-disponibles' : '/consumidores/cupones'}
            variant="outlined"
            size="large"
            color="primary"
            className={classes.buttons}>
            <img className={classes.imgIcons} src={couponIcon} alt="icon-cupon" />
            PROMOCIONES
          </Button>
          {lineBreak()}

          <Button
            component={Link}
            to={'/consumidores/puntos-venta'}
            variant="outlined"
            size="large"
            color="primary"
            className={classes.buttons}>
            <img className={classes.imgIcons} src={CentrosIcon} alt="icon-centros-canje" />
            PUNTOS DE VENTA
          </Button>
          {lineBreak()}
     

          {Object.keys(userLoggedIn).length !== 0 ? (
            <Button
              component={Link}
              to={userLoggedIn?.token ? '/app/mis-cupones-canjeados' : '/account/login'}
              variant="outlined"
              size="large"
              color="primary"
              className={classes.buttons}>
              <img className={classes.imgIcons} src={favIcon} alt="icon-mis-cupones" />
              Cupones Exclusivos
            </Button>
          ) : null}
          {lineBreak()}
          {Object.keys(userLoggedIn).length !== 0 ? (
            <Button
              component={Link}
              to={userLoggedIn?.token ? '/app/mi-billetera-envase' : '/account/login'}
              variant="outlined"
              size="large"
              color="primary"
              className={classes.buttons}>
              <img className={classes.imgIcons} src={billeteraIcon} alt="icon-mis-cupones" />
              BILLETERA DE ENVASES
            </Button>
          ) : null}
          {lineBreak()}*/}
        </div>
        <img className={classes.imgBanner} src={bannerCoca} alt="banner-coca" />

      </div>
    </div>
  );
}
