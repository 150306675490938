import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import firebase from 'firebase';
//import { initializeApp } from "firebase/app";
//import { getAuth } from "firebase/auth";

import Navbar from '../../commons/navBar';
import { paleta } from '../../../assets/colors';

import icon_mail from '../../../assets/icons/icon-mail.png';
import icon_human from '../../../assets/icons/icon-human.png';
import icon_google from '../../../assets/icons/icon_google.png';
//import icon_facebook from "../../../assets/icons/facebook.png";
// import icon_facebook from '../../../assets/icons/facebook-disabled.png';
//import icon_apple from "../../../assets/icons/icon_apple.png";
import { actLogin } from '../../../redux/actions/loginActions';
import { useStyles } from '../../stylesAuth';
import { registerUser } from '../../../request';
import { Button, CircularProgress } from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import NavBarTitle from '../../commons/navBarIcon';


/**
 * LOGIN
 */
export default function ({ history }) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const loginReducer = useSelector((state) => state.loginReducer);

  const { handleSubmit, register, errors } = useForm();
  const [err, setErr] = useState();
  const [loading, setLoading] = useState(false)
  const [loadingGoogle, setLoadingGoogle] = useState(false)


  const providerGoogle = new firebase.auth.GoogleAuthProvider();
  const providerFacebook = new firebase.auth.FacebookAuthProvider();

  // Login solo email
  const onSubmit = ({ userEmail, password }) => {
    setLoading(true);
    setErr();
    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => {
        return firebase.auth().signInWithEmailAndPassword(userEmail, password);
      })
      .then(async (userCredential) => {
        const { uid } = userCredential.user;
        const user = userCredential.user;
        const token = await user.getIdToken();

        console.log('userCredential.user: ', userCredential.user);
        dispatch(
          actLogin({
            uid: uid,
            social_network: 'Password',
            first_name: userEmail,
            last_name: '',
            profile_image: '',
            email: userEmail,
            token: user.uid,
          })
        );

        history.push('/consumidores/home');
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        setErr(errorCode);
        console.log('Sale por error: ', errorCode, errorMessage);
        setLoading(false);
      });

  };

  // Login Facebook
  /*const loginWithFacebook = () => {
    firebase
      .auth()
      .signInWithPopup(providerFacebook)
      .then((userCredential) => {
        console.log('userCredential nuevoooo', userCredential);
        const { id, first_name, last_name, email, picture } = userCredential.additionalUserInfo.profile;
        const { isNewUser } = userCredential.additionalUserInfo;
        const { xa } = userCredential.user;
        dispatch(
          actLogin({
            uid: id,
            social_network: 'Facebook',
            first_name: first_name,
            last_name: last_name,
            profile_image: picture.data.url,
            email: email ? email : `${first_name}@fbfemsa.com`,
            token: xa,
          })
        );

        if (isNewUser) {
          registerUser(
            {
              uid: id,
              social_network: 'Facebook',
              first_name: first_name,
              last_name: last_name,
              profile_image: picture.data.url,
              email: email ? email : `${first_name}@fbfemsa.com`,
            }
          );
        }

        history.push('/consumidores/home');
      })
      .catch((error) => {
        // Handle Errors here.
        //var errorCode = error.code;
        //var errorMessage = error.message;
        // The email of the user's account used.
        //var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        //var credential = error.credential;
      });
  };*/

  // Login Google
  const loginWithGoogle = () => {
    setLoadingGoogle(true)
    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => {
        return firebase.auth().signInWithPopup(providerGoogle);
      })
      .then(async (userCredential) => {
        const { id, email, given_name, family_name, verified_email, picture } =
          userCredential.additionalUserInfo.profile;
        const { isNewUser } = userCredential.additionalUserInfo;
        const { ya, uid } = userCredential.user;
        console.log('userCredential.user: ', userCredential);
        dispatch(
          actLogin({
            uid: uid,
            social_network: 'Google',
            first_name: given_name,
            last_name: family_name,
            profile_image: picture,
            email: email,
            token: uid,
          })
        );

        if (isNewUser) {
          console.log('newwwww')
          registerUser(
            {
              uid: uid,
              social_network: 'Google',
              first_name: given_name,
              last_name: family_name,
              profile_image: picture,
              email: email,
            }
          );
        }

        history.push('/consumidores/home');
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        var email = error.email;
        var credential = error;
        console.log(errorCode, errorMessage, email, credential);
        setLoadingGoogle(false)
      });
  };

  /*   const googleBtn = () => (
    <button className={classes.buttonGoogle}>Seguir con Google</button>
  );

  const facebookBtn = () => (
    <div className={classes.buttonFacebook}>Seguir con Facebook</div>
  ); */

  useEffect(() => {
    let exec = true;
    if (exec) {
      if (loginReducer.length) {
        history.push('/app/misCanjeados');
      }
    }
    return () => {
      exec = false;
    };
  }, [loginReducer]);

  const firebaseGoogleFacebookRegister = () => (
    <div className={classes.buttonContainer}>
      <div style={{ color: paleta.blanco, marginRight: '10px', fontSize: '16px', marginBottom: '20px' }}>
        O ingresar con:
      </div>

      <Button
        variant="contained"
        color="default"
        onClick={loginWithGoogle}
        className={classes.btnSocial}
        startIcon={<img src={icon_google} alt="Google Icon" width={'18px'} />}>
        {loadingGoogle ? <CircularProgress color='error' size={20} /> : <>Google</>}
      </Button>

      {/* <Button
        variant="contained"
        // color="inherit"
        className={classes.btnSocial}
        //style={{ backgroundColor: 'black', color: 'white' }}
        startIcon={<img src={icon_apple} alt="Apple Icon" width={'25px'} />}>
        Ingresar con IOS
      </Button> */}
      <span style={{ color: paleta.blanco, marginRight: '10px', fontSize: '14px', marginTop: '20px' }}>
        ¿No tenés cuenta?
      </span>
      <span className={classes.btnsRegister} onClick={() => history.push('./register')}>
        Registrate
      </span>
    </div>
  );

  const registerForm = () => (
    <form className={classes.formContent} onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.inputsContainersText}>


        <p style={{ color: paleta.blanco, fontSize: '18px', textAlign: 'center', fontWeight: 'bold' }}>
          {' '}
          Inicio de sesión
        </p>

        <div className={classes.inputContainer}>
          {/* <img className={classes.icon} src={icon_mail} alt="img-mail" />*/}
          <input
            name="userEmail"
            className={classes.input}
            autoCapitalize="none"
            placeholder="Ingrese su email"
            ref={register({
              required: 'Campo obligatorio.',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Correo invalido.',
              },
            })}
          />
        </div>

        {errors.userEmail && errors.userEmail.message && (
          <p className={classes.errorText}>{errors.userEmail.message}</p>
        )}

        <div className={classes.inputContainer}>
          {/*<img className={classes.icon} src={icon_human} alt="img-mail" />*/}
          <input
            name="password"
            type="password"
            autoCapitalize="none"
            placeholder="Ingrese su contraseña"
            className={classes.input}
            ref={register({
              required: 'Campo obligatorio.',
            })}
          />
        </div>
        {errors.password && errors.password.message && <p className={classes.errorText}>{errors.password.message}</p>}
      </div>
      {err ? <p className={classes.loginError}>Usuario o contraseña incorrectos</p> : null}
      <div className={classes.btnRegister}>
        <button className={classes.button} type="submit">
          {loading ? <CircularProgress color='error' size={20} /> : <>INGRESAR</>}
        </button>
        {/*   <p
          style={{ color: paleta.blanco }}
          onClick={() => history.push("./register")}
        >
          Crear mi cuenta
        </p> */}
      </div>
      <div style={{ flex: 1 }}></div>
    </form>
  );

  return (
    <div
      style={{
        backgroundColor: paleta.rojoPrincipal,
        height: '100vh',
      }}>
      <Navbar logoRojo={false} colorFondo={paleta.bordo} history={history} />
      {/*<div className={classes.bannerTitle}>
        <NavBarTitle img={icon_human} title="Inicio de Sesión" />
    </div>*/}

      {registerForm()}
      {firebaseGoogleFacebookRegister()}
    </div>
  );
}
