import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import GridCoupons from "../../commons/gridCoupons";
import Navbar from "../../commons/navBar";
import { getCouponsUser, getHistoryCoupons } from "../../../request";

import iconNav from "../../../assets/icons/_ICONO MIS CUPONES.png";
import { CircularProgress } from "@material-ui/core";
import { useStyles } from "../qrScanner/styleQr";
import { Button } from "@material-ui/core";
import { NavBarTitleCustom } from "../../commons/navBar/NavBarTitleCustom";
import empty from "../../../assets/images/empty2.gif";
import AppBar from "../../commons/appBar/index";

const MyBurnCoupons = ({ history }) => {
  const classes = useStyles();
  const { loginReducer } = useSelector((state) => state);
  const { token } = loginReducer.userLoggedIn;
  const [myCouponsHistory, setMyCouponsHistory] = useState([]);
  const [myCouponsResp, setMyCouponsResp] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let exec = true;
    if (exec) {
      if (token) {
        getMyHistoryCoupons();
        getMyCoupons();
      }
    }
    return () => {
      exec = false;
    };
  }, []);

  const getMyHistoryCoupons = async () => {
    await getHistoryCoupons(token).then((res) => {
      setMyCouponsHistory(res);
      setLoading(false);
    });
  };

  const getMyCoupons = async () => {
    await getCouponsUser(token).then((res) => {
      setMyCouponsResp(res);
      setLoading(false);
    });
  };

  const noLogged = () => (
    <div className={classes.okScreen}>
      <div
        style={{
          textAlign: "center",
          marginBottom: "10%",
        }}
      >
        <h2>
          No disponible
          <br />
        </h2>
        <p>Debe iniciar sesion para poder visualizar sus cupones.</p>
      </div>
      <Button
        variant="contained"
        className={classes.button2}
        onClick={() => history.push("/account/login")}
      >
        Iniciar Sesion
      </Button>
    </div>
  );

  const emptyListCoupons = () => (
    <div className={classes.okScreen}>
      <div>
        {/* <ErrorOutlineOutlinedIcon style={{ fontSize: '20vh', color: '#115293' }} /> */}
        <img src={empty} alt="Descripción del GIF" width={"100%"} />
      </div>
      <div
        style={{
          textAlign: "center",
          marginBottom: "10%",
        }}
      >
        <h2>No hay cupones disponibles</h2>
        <p>
          En esta sección podrás obtener cupones con beneficios excusivos de tu
          productos preferidos.
        </p>
      </div>
    </div>
  );

  return (
    <>
      <Navbar
        link="./home"
        logoRojo={true}
        colorFondo={"white"}
        history={history}
      />

      <NavBarTitleCustom titleCustom={"CUPONES EXCLUSIVOS"} iconNav={iconNav} />
      {token ? (
        loading ? (
          <div className={classes.containerLoading}>
            <CircularProgress disableShrink />
          </div>
        ) : myCouponsResp.length > 0 || myCouponsHistory.length > 0 ? (
          <div style={{ height: "70vh" }}>
            {myCouponsResp.length > 0 && (
              <GridCoupons coupons={myCouponsResp} history={history} />
            )}
            {myCouponsHistory.length > 0 && (
              <GridCoupons
                burn={true}
                coupons={myCouponsHistory}
                history={history}
              />
            )}
          </div>
        ) : (
          emptyListCoupons()
        )
      ) : (
        noLogged()
      )}

      {token && <AppBar />}
    </>
  );
};

export default MyBurnCoupons;
