import React from 'react';
import { IconButton, Toolbar } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import logo_coca_cola from '../../../assets/icons/_LOGO HEADER.png';
//import logo_coca_cola_rojo from '../../../assets/icons/_LOGO HEADER ROJO.png';
import Drawer from '../drawer';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import { actLogout } from "../../../redux/actions/loginActions";
import { useStyles } from './styles';
import { paleta } from '../../../assets/colors';
import loginIcon from "../../../assets/icons/logUSER.png";
import logoutIcon from "../../../assets/icons/iconexit.png";

import { styled, Button, Popover, Typography, Grid, Modal, Box, Card, CardHeader, CardMedia, CardContent, CardActions, Collapse, CircularProgress } from "@material-ui/core";
//import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';



export default function ButtonAppBar({
  link,
  history,
  colorFondo,
  logoRojo = true }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { loginReducer } = useSelector((state) => state);
  const { userLoggedIn } = loginReducer;
  const { token } = useSelector((state) => state.loginReducer.userLoggedIn);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notifications, setNotifications] = React.useState([]);
  const [expanded, setExpanded] = React.useState(false);
  const [loading, setLoading] = React.useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleClick = (event) => {
    //setAnchorEl(event.currentTarget);
  };

  const handleCloseNoti = () => {
    setAnchorEl(null);
  };

  const openNoti = Boolean(anchorEl);
  const id = openNoti ? 'simple-popover' : undefined;

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const handleLogout = () => {
    setLoading(true)
    dispatch(actLogout());
    history.push("/consumidores");
    setLoading(false)
  };

  function ImageIcon({ dir }) {
    return (
      <div>
        <img src={dir} alt="icon" style={{ width: 25 }} />
      </div>
    );
  }

  const styleModal = {
    position: "absolute",
    top: "30%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 200,
    bgcolor: "background.paper",
    //border: "2px solid red",
    borderRadius: "10px",
    boxShadow: 30,
    p: 4,
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (

    <div className={classes.appBar}>
      <Toolbar
        style={{
          backgroundColor: paleta.rojoCoca,
          paddingLeft: 0,
          paddingRight: 0,
        }}>

        {history && (history.location.pathname != '/account/login' && history.location.pathname != '/account/register') &&

          <IconButton
            edge="start"
            className={classes.menuButtonLeft}
            aria-label="menu"
            onClick={(e) => {
              if (history.location.pathname === '/app/cupon' || history.location.pathname === '/app/tienda-qr') {
                history.goBack()
              }
              else {
                handleClick(e)
              }
            }}
          >
            {
              history && (history.location.pathname != '/app/cupon' && history.location.pathname != '/app/tienda-qr')
                ? <NotificationsNoneIcon style={{
                  color: paleta.grisClaro
                }} />
                : <ArrowBackIcon style={{
                  color: paleta.blanco
                }} />
            }
          </IconButton>

        }



        <div onClick={() => history.push('/consumidores/home')} className={classes.title}>
          <img className={classes.imgNavbar}
            // src={logoRojo ? logo_coca_cola_rojo : logo_coca_cola} alt="img-coca-cola" />
            src={logo_coca_cola} alt="img-coca-cola" />
        </div>

        <div className={classes.menuButtonRight}>
          {!token ? (
            <IconButton
              edge="start"
              aria-label="menu"
              onClick={() => history.push("/account/login")}
            >
              <ImageIcon dir={loginIcon} />
            </IconButton>
          ) : (
            <IconButton
              edge="start"

              aria-label="menu"
              onClick={handleOpen}
            >
              <ImageIcon dir={logoutIcon} />
            </IconButton>

          )}


          {/*<Drawer
            colorCustom={paleta.blanco}
          history={history} />*/}
        </div>


      </Toolbar>

      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        id={id}
        open={openNoti}
        anchorEl={anchorEl}
        onClose={handleCloseNoti}
        PaperProps={{
          style: {
            boxShadow: '#000 1px 10px 30px', // Aplicar sombra
            borderRadius: '10px'
          },
        }}
      >
        <div className={classes.notipopover}>
          <h2 style={{ display: 'flex', justifyContent: 'center', margin: '0px', color: 'white', backgroundColor: '#da1325', padding: '0.6rem' }}>Notificaciones</h2>
          {notifications.length === 0 ?
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Typography style={{ padding: '0.6rem' }}>No hay notificaciones</Typography>
            </div>
            :
            <div className={classes.boxNotis}>
              {notifications.map((not) =>
              (
                <div className={classes.boxNoti}>
                  <Card style={{ minWidth: ' 100%' }}>
                    <CardHeader
                      title={not.title}
                    />
                    <CardActions disableSpacing>
                      <ExpandMore
                        expand={expanded}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                      >
                        <ExpandMoreIcon />
                      </ExpandMore>
                    </CardActions>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                      <CardContent>
                        <Typography paragraph>
                          {not.description}
                        </Typography>

                      </CardContent>
                    </Collapse>
                  </Card>


                </div>

              )
              )}
            </div>
          }


        </div>

      </Popover>

      <Modal
        className={classes.modal}
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            ...styleModal,
            width: "90vw",
            height: "25vh",
            backgroundColor: "#da1325",
          }}
        >

          <h3
            id="parent-modal-title"
            style={{ textAlign: "center", color: "white" }}
          >
            Esta seguro que desea salir?
          </h3>

          <div style={{ textAlign: "center" }}>

            <Button variant="outlined" className={classes.buttonExit}
              onClick={() => handleClose()}>
              NO
            </Button>
            <Button variant="outlined" className={classes.buttonExit}
              onClick={() => handleLogout()}>
              {loading ? <CircularProgress /> : <>SI</>}
            </Button>


          </div>
        </Box>
      </Modal>
    </div>


  );
}
