import { makeStyles } from '@material-ui/core/styles';



export const useStyles = makeStyles((theme) => ({

    tabAppBar: {
        position: 'fixed',
        bottom: 0,
        textAlign: 'center',
        display: 'flex',
        margin: 'auto',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        gap: '1rem', // Puedes ajustar el valor según tus necesidades
        zIndex: 50,
        backgroundColor: '#da1325',
        color: 'white',
        height: '4rem',

    },
    iconAppBar: {
        width: '50%',
    },
    tabsAppBar: {
        fontSize: '8px',
        height: '4rem',
    },
    tabContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center", /* Centra la imagen y el texto horizontalmente */
    },

    iconAppBar: {
        height: "30px", /* Establece la altura deseada para la imagen */
        width: "auto", /* Mantiene la proporción original de la imagen */
    }



}));