import { makeStyles } from '@material-ui/core';
import colors, { paleta } from '../assets/colors';
const facebookCol = '#3976ab';
const googleCol = '#c95b51';

export const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.palette.primary,
    height: '96vh',
    maxWidth: 750,
  },

  inputsContainersText: {
    flex: 3,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },

  btnRegister: {
    flex: 1,
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  formContent: {
    flex: 1,
    display: 'flex',
    height: '55vh',
    flexDirection: 'column',
    padding: '0 0 2vh 0',
    alignItems: 'center',
  },

  input: {
    flex: 1,
    width: '75vw',
    padding: '18px 14px',
    paddingLeft: 15,
    backgroundColor: '#da1325',
    //borderRadius: '10px',
    borderBottom: '1px solid #ffffff',
    border: 'none',
    outline: 'none',
    color: '#ffffff',
    '&::placeholder': {
      color: '#ffffff',
    },
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    margin: '14px 0px',

    marginLeft: 'auto',
    marginRight: 'auto',
    width: '80vw',
  },
  icon: {
    width: 70,
    padding: 6,
    position: 'absolute',
    left: '-15px',
  },
  socialMediaIcon: {
    width: 60,
    padding: 5,
  },
  button: {
    cursor: 'pointer',
    borderRadius: '10px',
    backgroundColor: 'white',
    color: '#da1325',
    fontWeight: 'bold',
    border: 'none',
    paddingLeft: 14,
    paddingRight: 14,
    paddingTop: 10,
    paddingBottom: 10,
    height: '8vh',
    width: '80vw',
    alignSelf: 'center',
    outline: 'none',
    "&:hover": {
      backgroundColor: "#da1325",
      color: 'white',
      border: '1px solid white' // Cambia el color al pasar el mouse
    },
  },
  errorText: {
    color: paleta.blanco,
    margin: 0,
    padding: '0.1%',
  },
  btnSocial: {
    width: '55vw',
    marginBottom: '2%',
    textTransform: 'none',
  },

  buttonContainer: {
    height: '25vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '0 0 10vw 10vw',
    backgroundColor: paleta.rojoCoca,
    margin: '2vh',
  },
  buttonGoogle: {
    backgroundColor: googleCol,
    flex: 1,
    width: '55vw',
    borderRadius: '0 10px 10px 0',
    height: 46,
    color: 'white',
    fontSize: 'medium',
    textAlign: 'justify',
    paddingLeft: '18px',
    borderStyle: 'solid',
    borderColor: 'white',
    borderWidth: 1,
  },
  buttonFacebook: {
    backgroundColor: facebookCol,
    flex: 1,
    width: '55vw',
    borderRadius: '0 10px 10px 0',
    height: 46,
    color: 'white',
    fontSize: 'medium',
    textAlign: 'justify',
    paddingLeft: '18px',
    borderStyle: 'solid',
    borderColor: 'white',
    borderWidth: 1,
    display: 'flex',

    alignItems: 'center',
    justifyContent: 'flex-start',
  },

  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    position: 'relative',
    maxWidth: 450,
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: '2%',
  },

  divFatherComponent: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    height: '95vh',
  },
  h1Title: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    color: 'white',
    marginBottom: 0,
  },
  loginError: {
    margin: 0,
    fontSize: 16,
    color: 'white',
    textAlign: 'center',
  },
  inputContainerButtons: {
    flex: 1.6,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    width: '100%',
  },
  containerButtons: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    width: '100%',
    marginBottom: '10%',
  },
  bannerTitle: {
    backgroundColor: "#da1325",
    height: "6vh",
    display: "flex",
    borderRadius: "0 0 10px 10px",
    alignContent: "center",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    top: "-2px",
    zIndex: 9,
  },
  btnsRegister: {
    color: 'white',
    fontWeight: 'bold',
    "&:hover": {
      cursor: "pointer",
      textDecoration: 'underline',

    },
  }
}));
