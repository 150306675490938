
import React, { useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import { Tabs, Tab } from "@material-ui/core";

import homeIcon from '../../../assets/icons/_ICONO HOME.png';
import couponIcon from '../../../assets/icons/_ICONO CUPONES.png';
import CentrosIcon from '../../../assets/icons/_ICONO CENTRO DE CANJE.png';
import favIcon from '../../../assets/icons/_ICONO MIS CUPONES.png';
import billeteraIcon from "../../../assets/icons/_ICONO ENVASES.png";
import homeIcon1 from '../../../assets/icons/_ICONO HOME-I.png';
import couponIcon1 from '../../../assets/icons/_ICONO PROMO-I.png';
import CentrosIcon1 from '../../../assets/icons/_ICONO LOCAL-I.png';
import favIcon1 from '../../../assets/icons/_ICONO MIS CUPONES-I.png';
import billeteraIcon1 from "../../../assets/icons/_ICONO ENVASES-I.png";
import { useStyles } from './styles';

export default function Appbar() {
    //const { theme } = useTheme();
    const [value, setValue] = useState(0);
    const history = useHistory();
    const pathname = history.location.pathname;
    //const { width } = useScreen();
    const userLoggedIn = { token: "123123" }
    const classes = useStyles();

    const tabs = [
        { label: "Inicio", icon: homeIcon, icon1: homeIcon1, path: '/home' },
        { label: "Promociones", icon: couponIcon, icon1: couponIcon1, path: userLoggedIn?.token ? '/app/mis-cupones-disponibles' : '/consumidores/cupones' },
        { label: "Puntos de Venta", icon: CentrosIcon, icon1: CentrosIcon1, path: '/consumidores/puntos-venta' },
        { label: "Cupones Exclusivos", icon: favIcon, icon1: favIcon1, path: '/app/mis-cupones-canjeados' },
        { label: "Billetera de Envases", icon: billeteraIcon, icon1: billeteraIcon1, path: '/app/mi-billetera-envase' },
    ];

    const handleChange = (newValue) => {
        setValue(newValue);
    };
    //const isClientsDetailPage = pathname.startsWith("/home");

    useEffect(() => {
        let selectedTabIndex = tabs.findIndex((tab) => tab.path === pathname);
        //let selectedTabIndex = 1;
        /*if (isClientsDetailPage) {
            selectedTabIndex = 1;
        }*/
        setValue(selectedTabIndex);
    }, [pathname]);
    //}, []);

    return (
        <Tabs
            className={classes.tabAppBar}
            value={value}
            onChange={handleChange}
            aria-label="icon tabs example"
            centered={true}
            variant={"fullWidth"}
            TabIndicatorProps={{
                style: {
                    backgroundColor: "#ff3434",
                    height: "3px",
                },
            }}
            textColor="inherit"
        >
            {tabs.map((tab, index) => (
                <Tab
                    className={classes.tabsAppBar}
                    component={Link}
                    to={tab.path}
                    key={index}
                    label={
                        <div className={classes.tabContent}>
                            <img className={classes.iconAppBar} src={value === index ? tab.icon : tab.icon1} alt="icono" />
                            {/*value === index && <span>{tab.label}</span>*/}
                        </div>
                    }
                    aria-label={tab.label}
                //onClick={() => router.push(tab.path)}
                //disabled={!isAuthenticated && index !== 0}
                />

            ))}
        </Tabs>
    );
}
