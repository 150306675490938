import React from "react";
import clsx from "clsx";
import { useSelector, useDispatch } from "react-redux";

import { makeStyles, SwipeableDrawer, Button, List, Divider, ListItem, ListItemIcon, Box, ListItemText } from "@material-ui/core";

import { Menu } from "@material-ui/icons";
import billeteraIcon from "../../../assets/icons/drawer/icon-billetera-white.png";
import iconUser from "../../../assets/icons/drawer/icon-user-whitw.png";
import homeIcon from "../../../assets/icons/drawer/icon-01-white.jpg";
import centrosIcon from "../../../assets/icons/drawer/icon-02-whte.png";
import couponsIcon from "../../../assets/icons/drawer/icon-03-white.png";
import favIcon from "../../../assets/icons/drawer/icon-04-white.jpg";
import loginIcon from "../../../assets/icons/drawer/icon-05-white.jpg";
import logoutIcon from "../../../assets/icons/drawer/icon-06-whire.jpg";
import { actLogout } from "../../../redux/actions/loginActions";

import styles from "../../../assets/colors";

const useStyles = makeStyles({
  list: {
    flex: 1,
    width: 280,
    paddingTop: 10,
    backgroundColor: "white",
  },
  fullList: {
    width: "auto",
    backgroundColor: "#231f20",
  },
  button: {
    padding: 15,
    margin: "6px 0px",
    alignItems: "center",
  },
  iconBtn: {
    paddingLeft: 12,
  },
  text: {
    color: styles.palette.white,
  },
  title: {
    fontSize: "large",
    flex: 1,
    display: "flex",
    justifyContent: "center",
    color: "black",
  },
  icon: {
    color: "black",
    fontSize: "calc(100vw * 17 / 375)",
    fontFamily: "TCCC-UnityCondensed",
    marginLeft: "calc(100vw * 11 / 375)",
  },
});

export default function Drawer({ history, colorCustom }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { uid, social_network, first_name, last_name, profile_image, email, token } = useSelector((state) => state.loginReducer.userLoggedIn);

  //console.log(token, "pruebla");
  const iOS = typeof navigator !== "undefined" && /iPad|iPhone|iPod/.test(navigator.userAgent);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  function ImageIcon({ dir }) {
    return (
      <div>
        <img src={dir} alt="icon" style={{ width: 40 }} />
      </div>
    );
  }
  //TODO, chequear si se usa luego este comportamiento
  /* function TextIcon({ dir, promo = false }) {
    return (
      <div>
        {promo ? (
          <img
            src={dir}
            alt="icon"
            style={{
              width: 76,
              marginLeft: 12,
            }}
          />
        ) : (
          <img
            src={dir}
            alt="icon"
            style={{
              width: 100,
              marginLeft: 12,
            }}
          />
        )}
      </div>
    );
  } */

  const items = [
    {
      tag: "home",
      name: "HOME",
      onClick: () => history.push("/consumidores/home"),
      icon: <ImageIcon dir={homeIcon} />,
      show: true,
    },
    {
      tag: "cupones",
      name: "PROMOCIONES",
      onClick: () => {
        history.push("/app/mis-cupones-disponibles");
        /*  if (token) {
          history.push("/app/mis-cupones-disponibles");
        } else {
          history.push("/consumidores/cupones");
        } */
      },
      icon: <ImageIcon dir={couponsIcon} />,
      show: true,
    },
    {
      tag: "mis-cupones",
      name: "Cupones Exclusivos",
      onClick: () => history.push("/app/mis-cupones-canjeados"),
      icon: <ImageIcon dir={favIcon} />,
      show: true,
    },
    {
      tag: "mi-billetera-envase",
      name: "BILLETERA DE ENVASES",
      onClick: () => history.push("/app/mi-billetera-envase"),
      icon: <ImageIcon dir={billeteraIcon} />,
      show: true,
    },
    {
      tag: "puntos-venta",
      name: "PUNTOS DE VENTA",
      onClick: () => history.push("/consumidores/puntos-venta"),
      icon: <ImageIcon dir={centrosIcon} />,
      show: true,
    },
    /*{
      tag: "billetera",
      name: "BILLETERA DE ENVASE",
      onClick: () => history.push("/consumidores/home"),
      icon: <ImageIcon dir={billeteraIcon} />,
      show: true,
    },*/
  ];

  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const handleLogout = () => {
    dispatch(actLogout());
    history.push("/consumidores");
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {token && (
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <div>
            <img
              style={{
                maxWidth: "45px",
                borderRadius: "50%",
              }}
              src={profile_image || iconUser}
            />
          </div>
          <div>
            <h5 className={classes.title}>
              Usuario:
              <br />
              {first_name} {last_name}
            </h5>
            <hr style={{ color: "white" }} />
          </div>
        </div>
      )}

      <List>
        {items.map(
          (elemento) =>
            elemento.show && (
              <ListItem button key={elemento.tag} onClick={(el) => elemento.onClick(el, anchor)} className={classes.button}>
                <ListItemIcon className={classes.iconBtn}>{elemento.icon}</ListItemIcon>
                <ListItemIcon className={classes.icon}>{elemento.name}</ListItemIcon>
              </ListItem>
            )
        )}
      </List>

      <Divider />

      <List>
        {token ? (
          <ListItem button onClick={handleLogout}>
            <ListItemIcon className={classes.iconBtn}>
              <ImageIcon dir={logoutIcon} />
            </ListItemIcon>
            <ListItemIcon className={classes.icon}>CERRAR SESION</ListItemIcon>
          </ListItem>
        ) : (
          <ListItem button onClick={() => history.push("/account/login")}>
            <ListItemIcon className={classes.iconBtn}>
              <ImageIcon dir={loginIcon} />
            </ListItemIcon>
            <ListItemIcon className={classes.icon}>INGRESAR</ListItemIcon>
          </ListItem>
        )}
      </List>
    </Box>
  );

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            <Menu style={{ color: colorCustom }} />
          </Button>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
            disableBackdropTransition={!iOS}
            disableDiscovery={iOS}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
