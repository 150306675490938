import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import firebase from "firebase/app";

import icon_mail from "../../../assets/icons/icon-mail.png";
import icon_human from "../../../assets/icons/icon-human.png";

import icon_google from "../../../assets/icons/google.png";
import icon_facebook from "../../../assets/icons/facebook.png";
// import icon_facebook from '../../../assets/icons/facebook-disabled.png';

import Navbar from "../../commons/navBar";

import { useStyles } from "../../stylesAuth";
import { paleta } from "../../../assets/colors";
import { actLogin } from "../../../redux/actions/loginActions";
import { registerUser } from "../../../request";
import { Button, CircularProgress } from '@material-ui/core';

export default function ({ history }) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [userRegister, setUserRegister] = useState(null);
  const [userExist, setUserExist] = useState(false);
  const [loading, setLoading] = useState(false)

  var providerGoogle = new firebase.auth.GoogleAuthProvider();
  var providerFacebook = new firebase.auth.FacebookAuthProvider();

  const { handleSubmit, errors, getValues, register } = useForm();

  // Registro manual
  const onSubmit = (data) => {
    setLoading(true);
    firebase
      .auth()
      .createUserWithEmailAndPassword(data.userEmail, data.password)
      .then((userCredential) => {
        const { isNewUser } = userCredential.additionalUserInfo;
        const { xa, uid } = userCredential.user;

        dispatch(
          actLogin({
            uid: uid,
            social_network: "Femsa",
            first_name: data.fullName,
            last_name: "Femsa",
            profile_image: "",
            email: data.userEmail,
            token: uid,
          })
        );

        if (isNewUser) {
          registerUser(
            {
              uid: uid,
              social_network: "Femsa",
              first_name: data.fullName,
              last_name: "",
              profile_image: "",
              email: data.userEmail,
            }
          );
        }

        //history.push("/account/login");
      })
      .catch((error) => {
        var errorCode = error.code;
        console.log(errorCode);
        var errorMessage = error.message;
        console.log(errorMessage);
        setLoading(false);
      });

  };

  // Registro con Google
  const registerWithGoogle = () => {
    setLoading(true);
    firebase
      .auth()
      .signInWithPopup(providerGoogle)
      .then((userCredential) => {
        const { id, email, given_name, family_name, verified_email, picture } =
          userCredential.additionalUserInfo.profile;
        const { isNewUser } = userCredential.additionalUserInfo;
        const { xa, uid } = userCredential.user;

        dispatch(
          actLogin({
            uid: uid,
            social_network: "Google",
            first_name: given_name,
            last_name: family_name,
            profile_image: picture,
            email: email,
            token: uid,
          })
        );

        if (isNewUser) {
          registerUser(
            {
              uid: uid,
              social_network: "Google",
              first_name: given_name,
              last_name: family_name,
              profile_image: picture,
              email: email,
            }
          );
        }

        history.push("/account/login");
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        var email = error.email;
        var credential = error;
        console.log(errorCode, errorMessage, email, credential);
        setLoading(false);
      });

  };

  const registerWithFacebook = () => {
    firebase
      .auth()
      .signInWithPopup(providerFacebook)
      .then((result) => {
        var user = result.user;

        dispatch(
          actLogin({
            email: user.email,
            name: user.displayName,
            token: user.xa,
          })
        );
      })
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        console.log(errorCode, errorMessage, email, credential);
      });
  };

  const googleBtn = () => (
    <button className={classes.buttonGoogle}> {loading ? <CircularProgress color='error' size={20} /> : <>Seguir con Google</>}</button>
  );

  const facebookBtn = () => (
    <button className={classes.buttonFacebook}>Seguir con Facebook</button>
  );

  const firebaseGoogleFacebookRegister = () => (
    <div className={classes.buttonContainer}>

      <div onClick={registerWithFacebook} className={classes.btnContainer}>
        <img
          src={icon_facebook}
          alt="logo-facebook"
          className={classes.socialMediaIcon}
        />
        {facebookBtn()}
      </div>

      <div onClick={registerWithGoogle} className={classes.btnContainer}>
        <img
          src={icon_google}
          alt="logo-google"
          className={classes.socialMediaIcon}
        />
        {googleBtn()}
      </div>
    </div>
  );

  const registerForm = () => (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.formContent}>
      <div className={classes.inputsContainersText}>
        {userExist && (
          <p className={classes.loginError}>
            El usuario ya se encuentra registrado
          </p>
        )}

        <div className={classes.inputContainer}>

          <input
            autoCapitalize="none"
            placeholder="Ingrese su nombre y apellido"
            name="fullName"
            label="fullName"
            variant="outlined"
            className={classes.input}
            ref={register({
              required: "Campo obligatorio.",
            })}
          />
        </div>
        {errors.fullName && errors.fullName.message && (
          <p className={classes.errorText}>{errors.fullName.message}</p>
        )}

        <div className={classes.inputContainer}>

          <input
            autoCapitalize="none"
            placeholder="Ingrese su email"
            className={classes.input}
            name="userEmail"
            ref={register({
              required: "Campo obligatorio",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Correo invalido.",
              },
            })}
          />
        </div>
        {errors.userEmail && errors.userEmail.message && (
          <p className={classes.errorText}>{errors.userEmail.message}</p>
        )}

        <div className={classes.inputContainer}>

          <input
            autoCapitalize="none"
            placeholder="Ingrese su contraseña"
            name="password"
            type="password"
            className={classes.input}
            ref={register({
              required: "Campo obligatorio",
              minLength: {
                value: 5,
                message: "La contraseña tiene que tener mas de 5 caracteres.",
              },
            })}
          />
        </div>

        {errors.password && errors.password.message && (
          <p className={classes.errorText}>{errors.password.message}</p>
        )}

        <div className={classes.inputContainer}>

          <input
            autoCapitalize="none"
            placeholder="Repita la contraseña"
            variant="outlined"
            name="passwordConfirm"
            type="password"
            className={classes.input}
            ref={register({
              required: "Campo obligatorio",
              validate: (value) => {
                if (value === getValues().password) {
                  return true;
                }
                return "Las contraseñas no coinciden.";
              },
            })}
          />
        </div>
        {errors.passwordConfirm && errors.passwordConfirm.message && (
          <p className={classes.errorText}>{errors.passwordConfirm.message}</p>
        )}
      </div>

      <div className={classes.btnRegister}>
        <button className={classes.button} type="submit">
          {loading ? <CircularProgress color='error' size={20} /> : <>REGISTRARME</>}
        </button>
        <p className={classes.btnsRegister}
          style={{ color: paleta.blanco, textAlign: 'center' }}
          onClick={() => history.push("./login")}
        >
          Ya tengo cuenta. Iniciar Sesión
        </p>
      </div>
    </form>
  );

  return (
    <div
      style={{
        backgroundColor: paleta.rojoPrincipal,
        height: "100vh",
      }}
    >
      <Navbar logoRojo={false} colorFondo={paleta.bordo} history={history} />
      <p style={{ color: paleta.blanco, fontSize: "18px", textAlign: "center", fontWeight: "bold", marginTop: '7vh' }}> Registrarse</p>
      {/*firebaseGoogleFacebookRegister()*/}
      {registerForm()}
    </div>
  );
}
