import React from 'react';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import Navbar from '../../commons/navBar';
import { useStyles } from './styleQr';
import { Button } from '@material-ui/core';
import { useLocation } from "react-router-dom";
import './index.css'

export const QrError = (props) => {
    const classes = useStyles();
    const { res, history } = props;
    const location = useLocation();
    const respuesta = location?.state;
    //console.log('respuestaa', respuesta)

    return (
        <div className={classes.containerMain}>
            <Navbar
                link="./home"
                logoRojo={true}
                colorFondo={'none'}
                history={history} />

            <div className={classes.okScreen}>
                <div>
                    <ErrorOutlineOutlinedIcon style={{ fontSize: '20vh', color: '#d78888' }} />
                </div>

                <div style={{ textAlign: 'center', marginBottom: '10%' }}>
                    {respuesta?.from === 'cupons' ?
                        <>
                            <h2>Oh oh!</h2>
                            <p>Cupon caducado o no disponible para este punto de venta.</p>
                        </>
                        :
                        <>
                            <h2>Oh oh!</h2>
                            <p>Error al escanear el codigo QR.</p>
                        </>
                    }

                </div>
                <Button variant="contained"
                    className={classes.button}
                    onClick={() => history.push('/app/consumidores/home')}>Volver a Inicio</Button>
            </div>
            <p>{res}</p>
        </div>
    )
}