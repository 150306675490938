import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getCouponsBurnByUser,
  getCouponsUser,
  getWalletBalance,
  getWalletHistory,
} from "../../../request";
//import GridCoupons from "../../commons/gridCoupons";
import Navbar from "../../commons/navBar";
import moment from "moment";
import QrReader from "react-qr-reader";
import {
  Grid,
  //Modal,
  //Box,
  Table,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  styled,
} from "@material-ui/core";
//import { tableCellClasses } from "@mui/material/TableCell";
import qrcode from "../../../assets/icons/qrcodewhite.png";
import CameraAltOutlinedIcon from "@material-ui/icons/CameraAltOutlined";
import iconNav from "../../../assets/icons/_ICONO ENVASES.png";
import { CircularProgress } from "@material-ui/core";
//import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import { useStyles } from "../qrScanner/styleQr";
import {
  Button,
  /*Avatar,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,*/
  Typography,
} from "@material-ui/core";
import { NavBarTitleCustom } from "../../commons/navBar/NavBarTitleCustom";
import empty from "../../../assets/images/empty2.gif";
import botella from "../../../assets/images/botlla1.png";
//import QrScanner from "../qrScanner";
import AppBar from "../../commons/appBar/index";
//import CocaRetornables from "../../../assets/images/cocacola-2.jpg";
import { getHistoryCoupons } from "../../../request";

const MyWallet = ({ history }) => {
  const classes = useStyles();
  const { loginReducer } = useSelector((state) => state);
  const { token } = loginReducer.userLoggedIn;
  const { uid } = loginReducer.userLoggedIn;
  const [myCouponsResp, setMyCouponsResp] = useState([]);
  const [balance, setBalance] = useState(0);
  const [loading, setLoading] = useState(true);
  const [logued, setLogued] = useState(false);
  const [myHistory, setMyHistory] = useState([]);

  useEffect(() => {
    let exec = true;
    if (exec) {
      console.log("uid token", token);
      if (token) {
        getHistory();
        setLogued(true);
        getBalance();
      } else {
        setLogued(false);
      }
      setLoading(false);
    }
    return () => {
      exec = false;
    };
  }, []);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${TableCell.head}`]: {
      backgroundColor: "rgb(251, 22, 43)",
      color: "white",
    },
    [`&.${TableCell.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const StyledTableHead = styled(TableHead)({
    top: 0,
    left: 0,
    zIndex: 2,
    position: "sticky",
  });

  const styleModal = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid red",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };

  const getBalance = async () => {
    await getWalletBalance(token).then((res) => {
      setBalance(res.balance);
      setLoading(false);
    });
  };

  const getHistory = async () => {
    await getWalletHistory(token).then((res) => {
      console.log("getHistorycupons 131 my wallet", res);
      setMyHistory(res);
    });
  };

  const agregarEnvases = () => {
    const data = { from: "wallet" };
    history.push({ pathname: "/app/tienda-qr", state: { from: "myWallet" } });
  };

  const emptyListCoupons = () => (
    <div className={classes.okScreen}>
      <div>
        {/* <ErrorOutlineOutlinedIcon style={{ fontSize: '20vh', color: '#115293' }} /> */}
        <img src={empty} alt="Descripción del GIF" width={"100%"} />
      </div>
      <div
        style={{
          textAlign: "center",
          marginBottom: "10%",
        }}
      >
        <h2>¡No juntes mas botellas!</h2>
        <p>
          Utilizando nuestra billetera podrás guardar tus envases digitales y
          canjearlos en tu comercio favorito al momento de hacer la compra.
        </p>
      </div>
      <Button
        variant="contained"
        className={classes.button}
        onClick={() => history.push("/account/login")}
      >
        Iniciar Sesion
      </Button>
    </div>
  );

  return (
    <>
      <Navbar
        link="./home"
        logoRojo={true}
        colorFondo={"white"}
        history={history}
      />

      <NavBarTitleCustom
        titleCustom={"BILLETERA DE ENVASES"}
        iconNav={iconNav}
      />
      {loading ? (
        <div className={classes.containerLoading}>
          <CircularProgress disableShrink />
        </div>
      ) : (
        <>
          {logued ? (
            <>
              <Grid
                container
                padding={0}
                rowspacing={0}
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  container
                  padding={0}
                  rowspacing={0}
                  justifyContent="center"
                  alignItems="center"
                  style={{
                    backgroundColor: "rgb(251, 22, 43)",
                    padding: "10px",
                    borderRadius: "20px",
                    margin: "1rem",
                    width: "90%",
                    boxShadow: "3px 5px 4px rgba(0, 0, 0, 0.8)",
                  }}
                >
                  <Grid
                    item
                    xs={3}
                    md={3}
                    style={{
                      justifyContent: "center",
                      display: "flex",
                      color: "white",
                    }}
                  >
                    <Typography
                      variant="h2"
                      style={{
                        fontWeight: "bold",
                        textShadow: "4px 4px 14px rgba(0, 0, 0, 0.8)",
                      }}
                    >
                      {balance}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    md={2}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={botella}
                      alt="Imagen izquierda"
                      style={{
                        width: "30%",
                      }}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    md={6}
                    style={{
                      display: "flex",
                      alignItems: "left",
                      flexDirection: "column",
                      justifyContent: "left",
                      color: "white",
                      lineHeight: "2",
                      fontWeight: "bold",
                    }}
                  >
                    <Typography
                      className="text-20 sm:text-22 truncate font-bold"
                      style={{ fontWeight: "bold", fontSize: "1.4rem" }}
                    >
                      ENVASES
                    </Typography>
                    <Typography
                      className="text-30 sm:text-32 truncate font-bold"
                      style={{ fontWeight: "bold", fontSize: "1.4rem" }}
                    >
                      DISPONIBLES
                    </Typography>
                  </Grid>
                </Grid>
                <p style={{ marginTop: "-1%", color: "#00000063" }}>
                  *Solo disponible para envases de 2L
                </p>
                {/* <Grid item xs={12} md={12} justifyContent="center">
                  <Card className={classes.card}>
                    <CardHeader
                      avatar={
                        <Avatar
                          style={{
                            backgroundColor: "white",
                            height: "70px",
                            width: "70px",
                            fontSize: "2.8rem",
                            fontWeight: "bold",
                            color: "rgb(251, 22, 43)",
                          }}
                          aria-label="recipe"
                        >
                         
                          12
                        </Avatar>
                      }
                      title="Envases Disponibles"
                      titleTypographyProps={{
                        style: {
                          fontSize: "20px",
                          color: "white",
                          fontWeight: "bold",
                        },
                      }}
                    />

                    <CardContent className={classes.cardContent}>
                      <Grid
                        container
                        padding={0}
                        rowSpacing={0}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid
                          item
                          xs={12}
                          md={12}
                          justifyContent="center"
                        ></Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>*/}
                <Grid
                  item
                  xs={12}
                  md={12}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0.5rem",
                  }}
                >
                  <Typography variant="h5">
                    {" "}
                    Ultimos canjes realizados
                  </Typography>
                </Grid>
                {myHistory.length > 0 ? (
                  <Grid item xs={11} md={11}>
                    <TableContainer
                      component={Paper}
                      style={{ maxHeight: "200px", overflow: "auto" }}
                    >
                      <Table aria-label="simple table">
                        <StyledTableHead
                          style={{
                            backgroundColor: "rgb(251, 22, 43)",
                          }}
                        >
                          <TableRow>
                            <StyledTableCell
                              style={{ color: "white", padding: "8px" }}
                            >
                              Fecha
                            </StyledTableCell>
                            <StyledTableCell
                              style={{ color: "white", padding: "8px" }}
                              align="center"
                            >
                              Comercio
                            </StyledTableCell>
                            <StyledTableCell
                              style={{ color: "white", padding: "8px" }}
                              align="right"
                            >
                              Cantidad
                            </StyledTableCell>
                          </TableRow>
                        </StyledTableHead>
                        <TableBody>
                          {myHistory.map((row) => (
                            <StyledTableRow
                              key={row.id}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <StyledTableCell
                                align="left"
                                style={{ padding: "5px", fontSize: "0.9em" }}
                              >
                                {moment(
                                  row.created_at,
                                  "DD/MM/YYYY HH:mm:ss"
                                ).format("DD/MM/YY HH:mm")}
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                scope="row"
                                style={{ padding: "5px", fontSize: "0.9em" }}
                              >
                                {row.commerce_name}
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                style={{ padding: "5px", fontSize: "0.9em" }}
                              >
                                {row.qty}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "0.5rem",
                    }}
                  >
                    <Typography> Actualmente no hay ningun canje </Typography>
                  </Grid>
                )}
                <Button
                  className={classes.floatingRedButton}
                  onClick={() => agregarEnvases()}
                >
                  Canjear
                  <img className={classes.iconQr} src={qrcode} alt="icono" />
                </Button>
              </Grid>
            </>
          ) : (
            emptyListCoupons()
          )}
        </>
      )}
      {logued && <AppBar />}
    </>
  );
};

export default MyWallet;
