/* eslint-disable react/no-array-index-key */
import React from "react";
import { CircularProgress, Typography, Paper } from "@material-ui/core";

import Coupon from "./coupon";
//import NavBarTitle from "../navBarIcon";

//import favorito from "../../../assets/icons/favorito.png";
import { useStyles } from "../../stylesCoupons";

const GridCoupons = ({ burn, titlePrincipal, coupons, history }) => {
  const classes = useStyles();
  console.log('coupons', coupons)
  /*const goLista = (e) => {
    e.preventDefault();
    history.push("/cupones");
  };*/

  return (

    <div className={classes.root}>
      <div className={classes.divFather} style={{ padding: '10px' }} >
        {/* <div className={classes.containerBackground}> */}
        <Typography style={{ fontWeight: "bold" }}>{titlePrincipal}</Typography>
        {/*!coupons?.length ? (
            <div className={classes.containerLoading}>
              <CircularProgress disableShrink />
            </div>
          ) : (
            <>
              {coupons.map((cupon, index) => (
                <div key={index}>
                  <Coupon burn={burn} keyCupon={index} cupon={cupon} history={history} />
                </div>
              ))}
            </>
          )*/}
        {coupons.map((cupon, index) => (
          <div key={index}>
            <Coupon burn={burn} keyCupon={index} cupon={cupon} history={history} />
          </div>
        ))}

        {/* </div> */}
      </div>
    </div>

  );
};

export default GridCoupons;
