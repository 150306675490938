import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { HashRouter } from 'react-router-dom';
import { store, persistor } from './store';
import './services/firebaseServices';
import logo from '../src/assets/images/_LOGO INICIO.png';
import { makeStyles } from '@material-ui/core';
import '../src/assets/fonts/stylesheet.css';

import Routes from './routes/routesIndex';
const useStyles = makeStyles((theme) => ({
  containerMsg: {
    backgroundColor: '#de171b',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    //    marginTop: -55,
    flexDirection: 'row',
    overflow: 'hidden',
    border: '-1px',
  },

  textMsg: {
    padding: 20,
    fontSize: '5.5vh',
    fontWeight: 'bold',
    color: 'white',
  },
}));



const App = function () {
  const [mobileScreen, setMobileScreen] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    // Función para realizar la detección del tipo de dispositivo
    const detectMobile = () => {
      if (/Android|iPhone/i.test(navigator.userAgent)) {
        setMobileScreen(true);
      } else {
        setMobileScreen(false);
      }
    };

    // Ejecutar la detección inicial
    detectMobile();

    // Agregar un listener para el evento de redimensionamiento de ventana
    window.addEventListener('resize', detectMobile);

    // Limpiar el listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('resize', detectMobile);
    };
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <HashRouter>
          {mobileScreen ? (
            <Routes />
          ) : (
            <div className={classes.containerMsg}>
              <p className={classes.textMsg}>¡App disponible únicamente para Dispositivos móviles!</p>

              <div>
                <img width="400px" src={logo} alt="onlysmartphone" />
              </div>
            </div>
          )}
        </HashRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
