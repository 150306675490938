import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useForm } from "../../../hooks/useForm";
import { useLocation } from "react-router-dom";
import QrReader from "react-qr-reader";
import CameraAltOutlinedIcon from "@material-ui/icons/CameraAltOutlined";

import Navbar from "../../commons/navBar";
import qr_title_2 from "../../../assets/images/qr_title_2.png";
import {
  createTransactionQRdata,
  createTransactionWalletQRdata,
} from "../../../request";
import { useStyles } from "./styleQr";
import "./index.css";
import { Scanner } from "@material-ui/icons";
import CryptoJS from "crypto-js";
import { CircularProgress } from "@material-ui/core";

function QrScanner({ history, match }) {
  const classes = useStyles();
  const location = useLocation();
  const [formValues, handleInputChange, reset] = useForm({});
  const { inputCode } = formValues;

  const { id, title } = useSelector(
    (state) => state.couponsReducer.coupon_selected
  );
  const { token } = useSelector((state) => state.loginReducer.userLoggedIn);
  const { uid } = useSelector((state) => state.loginReducer.userLoggedIn);

  const [scanAble, setScanAble] = useState(true);
  const [qrDetected, setQrDetected] = useState(false);
  const [loading, setLoading] = useState(false);
  const estadoPasado = location?.state;
  //console.log("estadoPasado", estadoPasado.from);
  useEffect(() => {
    let exec = true;
    if (exec) {
      if (qrDetected?.length > 7) {
        if (estadoPasado?.from === "myWallet") {
          createTransactionWalletQR({ qr: qrDetected });
        } else {
          createTransactionQR({ couponId: id, qrCode: qrDetected });
        }
        setLoading(false);
      }
    }
    return () => {
      exec = false;
    };
  }, [qrDetected]);

  const fromWordArray = (wordArray) => {
    var bytes = new Uint8Array(wordArray.sigBytes);
    for (var j = 0; j < wordArray.sigBytes; j++) {
      bytes[j] = (wordArray.words[j >>> 2] >>> (24 - 8 * (j % 4))) & 0xff;
    }
    return bytes;
  };

  /* ESCANEO */
  // cambiar por data
  const handleScan = async (data) => {
    console.log("aaca si", data, estadoPasado);
    if (data?.length > 7) {
      setLoading(true);
      if (estadoPasado?.from === "myWallet") {
        setQrDetected(data);
      } else {
        if (data.includes("FEMSA@")) {
          const result = data.split("@")[1];
          console.log("acaaaaaaa", data, result);
          setQrDetected(result);
        } else {
          history.push({
            pathname: "/app/cupon-error",
            state: { from: "cupons" },
          });
        }
      }
      setScanAble(false);
    }
  };

  /*function procesarDato(dato) {
    //let code = dato.split("@")[1];
    let codigo = "";
    let prefix = dato.split("@")[0];


    switch (prefix) {
      case "FEMSA":
        codigo = "111";
        break;
      case "CEGEK":
      case "REPRESENTACIONES TIGRE":
        codigo = "112";
        break;
      case "FERRARO":
        codigo = "113";
        break;
      case "GULY":
        codigo = "114";
        break;
      case "NORIA":
        codigo = "115";
        break;
      case "RUTA5":
        codigo = "116";
        break;
      case "SALEMA":
        codigo = "117";
        break;
      case "REBESA":
        codigo = "118";
        break;
      case "YAMANIL":
        codigo = "119";
        break;
      default:
        codigo = "000";
        break;
    }

    return dato.replace(prefix + "@", codigo);
    //return code;
  }*/

  const handleScanInput = () => {
    //createTransactionQR({ couponId: id, qrCode: inputCode });
    createTransactionWalletQR({ qr: inputCode });
  };
  /* ESCANEO */

  const createTransactionQR = async (data) => {
    await createTransactionQRdata(data, uid)
      .then((res) => {
        console.log("resssssssssssssssss", res);
        if (res.res === "Transaccion realizada correctamente") {
          // history.push("/app/cupon-ok", res);
          history.push({
            pathname: "/app/cupon-ok",
            state: { from: "cupons" },
          });
        } else {
          history.push({
            pathname: "/app/cupon-error",
            state: { from: "cupons" },
          });
          //history.push("/app/cupon-error", res);
        }
      })
      .catch((res) => console.error({ status: true, message: res }));
  };

  const createTransactionWalletQR = async (data) => {
    console.log("test", data);
    await createTransactionWalletQRdata(data, uid)
      .then((res) => {
        console.log("resssssssssssssssss", res);
        //if (res.res === "Transaccion realizada correctamente") {
        if (res.success) {
          history.push({
            pathname: "/app/cupon-ok",
            state: { from: "myWallet" },
          });
          //history.push("/app/cupon-ok", res);
        } else {
          history.push({
            pathname: "/app/cupon-error",
            state: { from: "myWallet" },
          });
          //history.push("/app/cupon-error", res);
        }
      })
      .catch((res) => console.error({ status: true, message: res }));
  };

  const handleError = (err) => console.error(err);

  const QRScaner = () => (
    <div className={classes.qr}>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <QrReader
          className="qr-image-wrapper"
          delay={500}
          onError={handleError}
          onScan={handleScan}
        />
      )}
    </div>
  );

  const inputTextQR = () => (
    <div className={classes.inputQR}>
      <img
        src={qr_title_2}
        style={{ width: 290 }}
        alt="qr-title"
        className={classes.title}
      />

      <div className={classes.formContainer}>
        <input
          placeholder="c1O3d4c5"
          className={classes.input}
          name="inputCode"
          value={inputCode}
          onChange={handleInputChange}
        />
        <button className={classes.btnOK} onClick={handleScanInput}>
          OK
        </button>
      </div>
    </div>
  );

  const bannerQr = () => (
    <div className={classes.camera}>
      <CameraAltOutlinedIcon style={{ fontSize: 40 }} />
      <p className={classes.textCamera}>Scanear código QR en caja</p>
    </div>
  );

  return (
    <div className={classes.containerMain}>
      <Navbar
        link="./home"
        logoRojo={true}
        colorFondo={"none"}
        history={history}
      />

      {bannerQr()}

      <div className={classes.divFather}>
        <div className={classes.containerBackground}>
          {scanAble && QRScaner()}
        </div>

        {/*inputTextQR()*/}

        <p>{scanAble}</p>
      </div>
    </div>
  );
}

export default QrScanner;
