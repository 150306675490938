import React, { useEffect, useState } from "react";
import { Button, CircularProgress, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../../commons/navBar";
import single_coupon from "../../../assets/icons/single_cupon.png";
import bannerPictures from "../../../assets/images/_DIBUJITOS.png";
import { setCouponSelected } from "../../../redux/actions/couponsActions";
import { paleta } from "../../../assets/colors";

const useStyles = makeStyles({
  // container: {
  //   display: 'flex',
  //   flexDirection: 'column',
  //   justifyContent: 'center',
  //   alignItems: 'center',
  //   height: '100vh',
  // },
  container: {
    height: "100vh",
  },
  content: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  bannerTop: {
    backgroundColor: "#b7b7b7",
    fontWeight: "bold",
    fontSize: "7vw",
    padding: "5px 10px",
  },
  circularProgress: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    backgroundColor: "white",
    justifyContent: "center",
  },
  button: {
    WebkitBorderRadius: "5px",
    borderRadius: "5px",
    backgroundColor: "#da1325",
    color: "#ffffffff",
    border: "none",
    alignSelf: "center",
    padding: 5,
    width: "65vw",
    letterSpacing: 2,
    marginBottom: "10%",
    marginTop: "-4rem",
  },
  textTitle: {
    textAlign: "center",
    color: "#202929ff",
    marginBottom: "3%",
    fontWeight: "bold",
    textTransform: "uppercase",
    fontSize: "16px",
  },
  text: {
    textAlign: "center",
    color: "#202929ff",
    margin: "0 5%",
    fontSize: "15px",
  },
  dueDate: {
    backgroundColor: "#b50000",
    color: "white",
    padding: 6,
    borderRadius: 15,
    fontWeight: "900",
  },
  iconContainer: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
  },

  infoContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  img: {
    borderTopWidth: 5,
    borderTopStyle: "solid",
    borderTopColor: "black",
    borderRadius: "3px",
    width: "100%",
    alignSelf: "center",
  },

  description: {
    textAlign: "center",
    color: "#202929ff",
    margin: 0,
    marginBottom: "6%",
  },

  containerZ: {
    backgroundColor: "#da1325",
    display: "flex",
    flex: 1,
    justifyContent: "center",
  },
  containerLoadingImage: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    position: "relative",
    left: "35%",
  },
});

const SingleCoupon = ({ history, match }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { coupon_selected } = useSelector((state) => state.couponsReducer);
  const { token } = useSelector((state) => state.loginReducer.userLoggedIn);

  const { id, due_date, enabled, img_brief, img_description } = coupon_selected;

  const [image, setImage] = useState("");
  const [loadingImage, setLoadingImage] = useState(true);

  useEffect(() => {
    let exec = true;
    if (exec) {
      if (img_description?.length) {
        setImage(img_description);
      } else {
        setImage(single_coupon);
      }
    }
    return () => {
      exec = false;
    };
  }, []);

  useEffect(() => {
    let exec = true;
    if (exec) {
      setTimeout(() => {
        setLoadingImage(false);
      }, 1000);
    }
    return () => {
      exec = false;
    };
  }, []);

  const onSubmmitCanje = () => {
    dispatch(setCouponSelected(coupon_selected));

    history.push(
      token
        ? { pathname: "/app/tienda-qr", state: { from: "cupon" } }
        : "/account/login"
    );
  };

  const loadingCoupon = () => {
    return (
      <div style={classes.circularProgres}>
        <CircularProgress />
      </div>
    );
  };

  const couponDetails = () => (
    <>
      {couponDescription()}
      <div
        style={{
          flex: 2,
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "flex-start",
          display: loadingImage ? "none" : "flex",
        }}
      >
        <Button
          variant="contained"
          className={classes.button}
          onClick={onSubmmitCanje}
        >
          canjear
        </Button>
        <img src={bannerPictures} style={{ width: "100%" }} />
      </div>
    </>
  );

  const couponDescription = () => (
    <div style={{ flex: 3, display: loadingImage ? "none" : "block" }}>
      <div>
        <img className={classes.img} src={image} alt="img-single" />
      </div>
    </div>
  );

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <Navbar
          link="/cupones"
          logoRojo={false}
          colorFondo={paleta.rojoCoca}
          history={history}
        />

        <div className={classes.container}>
          {loadingImage && loadingCoupon()}
          {couponDetails()}
        </div>
      </div>
    </div>
  );
};

export default SingleCoupon;
